import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box, Typography, Card, CardContent, InputAdornment, TextField, IconButton, CardMedia } from "@mui/material";
import { Color } from "../../GlobalStyles";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import Badge from "@mui/material/Badge";
import RDService from "../../services/RDService";
import WebinarSkeletonLoader from "./SkeletonLoaders/WebinarEventsSkeletonLoader";
import { ddmmm, convertEpochToNormalTime } from "../../services/specialCharReplace";
import { useWebinarContext } from "../../WebinarContext";
import NoEventsMessage from "./NoEventsMessage";
const WebinarCard = ({ key, mode, webinar, handleClick }) => {
  console.log("keykey", key, webinar);
  const Mobile = useMediaQuery("(max-width:800px)");
  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <Card
        sx={{
          minWidth: Mobile ? 100 : 275,
          width: "95%",
          background: mode === "past" ? "#f9f9f9" : webinar.is_active === 1 ? "#EBF9FC" : Color.white,
          border: "1px solid #E6E6E6",
          boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.08)",
          borderTopLeftRadius: "12px",
          borderBottomLeftRadius: "12px",
          marginBottom: "20px",
          cursor: "pointer",
          position: "relative",
          ...(Mobile && {
            flexDirection: "column",
            paddingTop: 1,
            paddingLeft: 1,
            paddingRight: 1,
            paddingBottom: 1,
          }),
        }}
        onClick={handleClick}
      >
        {mode === "upcoming" && (
          <div
            style={{
              position: "absolute",
              zIndex: 100,
              top: 10,
              left: 0,
              // borderRadius: "5px",
              // padding: Mobile ? "3px 3px" : "3px 10px",
              backgroundImage: `url("https://profile-picture-and-logo.s3.amazonaws.com/CareerCarve+App/Public/Frame+26087001.png")`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: Mobile ? "20%" : "10%",
              marginLeft: Mobile ? ".5rem" : ".8rem",
              // height: "18px",
            }}
          >
            <Badge
              badgeContent={""}
              // color={Color.webinarTicker}
              // sx={{
              //   color: "#fff",
              //   fontSize: "14px",
              //   fontWeight: "bold",
              //   marginBottom: "-15px",
              //   display: "inline-block",
              //   width: Mobile ? "20px" : "16px",
              //   textAlign: "left",
              //   whiteSpace: "nowrap",
              //   marginTop: "-30px",
              // }}
            />
          </div>
        )}

        <CardContent
          sx={{
            ...(Mobile && {
              padding: 0,
              paddingBottom: 0,
            }),
          }}
          style={{ ...(Mobile && { paddingBottom: "0" }) }}
        >
          <Box
            className="upcomingBookingContent"
            style={{
              height: mode === "past" && !Mobile ? "150px" : mode === "past" && Mobile ? "100px" : mode !== "past" && !Mobile ? "200px" : "100px",
              display: "flex",
              flexDirection: "row",
              gap: Mobile ? ".3rem" : "2rem",
              ...(Mobile && {
                padding: 0,
                paddingBottom: 0,
              }),
            }}
          >
            <Box className="upcomingBookingVideo">
              <Box>
                <Card
                  style={{
                    borderRadius: "11px",
                    height: mode === "past" && !Mobile ? "150px" : mode === "past" && Mobile ? "100px" : mode !== "past" && !Mobile ? "200px" : "100px",
                    width: Mobile ? "100px" : "320px",
                    objectFit: "cover",
                  }}
                >
                  <Box sx={{ position: "relative" }}>
                    <CardMedia component="img" style={{ width: "100%", height: "auto", maxHeight: "320px", objectFit: "cover", ...(Mobile && { height: "100px" }) }} image={Mobile ? webinar?.thumbnail : webinar?.thumbnail} />
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        width: "100%",
                        ...(mode === "past" && { bgcolor: "rgba(0, 0, 0, 0.32)" }),
                        ...(mode === "upcoming" && { background: "linear-gradient(to top, rgba(0, 0, 0), rgba(0, 0, 0, 0.92), transparent)" }),
                        color: "white",
                        padding: "10px",
                        paddingBottom: mode === "upcoming" ? "8px" : "48px",
                        ...(Mobile && { paddingBottom: mode === "upcoming" ? "3px" : "2px" }),
                      }}
                    >
                      <Typography sx={{ fontSize: Mobile ? "8px" : "20px", marginBottom: mode === "past" && !Mobile ? ".5rem" : 0, textAlign: "center" }}>
                        {ddmmm(webinar?.date)}, {convertEpochToNormalTime(webinar?.date)} IST
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              </Box>
            </Box>
            <Box
              className="upcomingBookingDescription"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: Mobile ? ".1rem" : ".5rem",
                justifyContent: "center",
              }}
            >
              <Typography variant="h5" sx={{ ...(Mobile && { fontSize: "12px" }) }}>
                <span style={{ fontWeight: 300, ...(Mobile && { fontWeight: "bold" }) }}>{webinar.title}</span>: {webinar.sub_title}
              </Typography>

              <Typography sx={{ fontSize: Mobile ? 10 : 18, width: "100%", color: Color.neutralMidGrey }} color="#000000" gutterBottom>
                {Mobile ? (webinar?.description.length > 35 ? `${webinar?.description.slice(0, 35)}...` : webinar?.description) : webinar?.description}
              </Typography>
              {mode === "upcoming" && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: ".5rem",
                      justifyContent: Mobile ? "flex-start" : "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: Mobile ? "left" : "center",
                      }}
                    >
                      <FmdGoodOutlinedIcon sx={{ width: Mobile ? "12px" : "20px", height: "20px", marginRight: Mobile ? ".2rem" : "0.5rem", color: Color.neutralMidGrey }} />
                      <Typography sx={{ margin: "auto 0", color: Color.neutralMidGrey, fontSize: Mobile ? "10px" : "16px" }}>{webinar.location}</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItem: Mobile ? "left" : "center",
                      }}
                    >
                      <CurrencyRupeeOutlinedIcon sx={{ width: Mobile ? "12px" : "20px", height: "20px", marginRight: Mobile ? ".2rem" : "0.5rem", color: Color.neutralMidGrey }} />
                      <Typography variant="body2" color="text.secondary" sx={{ margin: "auto 0", color: Color.neutralMidGrey, fontSize: Mobile ? "10px" : "16px" }}>
                        {webinar.fees === 0 ? "Free" : webinar.fees}
                      </Typography>
                    </Box>
                  </Box>
                  <button
                    className="pricingSingleACtivityButton1"
                    style={{
                      backgroundColor: Color.registerColor,
                      color: Color.white,
                      fontSize: Mobile ? "12px" : "16px",
                      ...(Mobile && {
                        display: "inline-block",
                        width: webinar?.registered === 1 ? "50%" : "70%",
                        height: "24px",
                        borderRadius: "6px",
                      }),
                    }}
                  >
                    {webinar?.registered === 1 ? "Registered" : "Register for Free"}
                  </button>
                  {!Mobile && webinar.registered > 200 && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItem: "center",
                        justifyContent: Mobile ? "center" : "flex-start",
                      }}
                    >
                      <PeopleAltOutlinedIcon sx={{ width: "20px", height: "20px", marginRight: "0.5rem" }} />
                      <Typography variant="body2" color="text.secondary" sx={{ margin: "auto 0" }}>
                        {webinar.registered} Attendees
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: Mobile ? "3%" : "5%",
          background: mode === "past" ? Color.neutralLightGrey : Color.primary1,
          marginBottom: "20px",
          borderTopRightRadius: "12px",
          borderBottomRightRadius: "12px",
          ...(Mobile && {
            width: "10%" /* Set width to 10% for mobile view */,
            transform: "none" /* Reset rotation for mobile view */,
            alignItems: "center" /* Align text to the center of the box in mobile view */,
            justifyContent: "center" /* Align box horizontally to center in mobile view */,
            padding: "10px" /* Adjust padding for mobile view */,
            height: "auto",
          }),
        }}
      >
        <Typography style={{ color: mode === "past" ? Color.neutralMidGrey : "white", fontWeight: "bold", transform: "rotate(-90deg)", textAlign: "center" }}>Webinar</Typography>
      </Box>
    </Box>
  );
};

const WebinarEvents = () => {
  const Mobile = useMediaQuery("(max-width:800px)");
  const { webinarTickerExists } = useWebinarContext();

  const navigate = useNavigate();
  const service = new RDService();
  const [upcomingWebinarEvents, setUpcomingWebinarEvents] = useState([]);
  const [recentWebinarEvents, setRecentWebinarEvents] = useState([]);
  const [responseDataEmpty, setResponseDataEmpty] = useState(false);
  const [recentResponseDataEmpty, setRecentResponseDataEmpty] = useState(false);
  const [loading, setLoading] = useState({ upcoming_loader: false, recent_loader: false });
  const [upcomingPageCount, setUpcomingPageCount] = useState(1);
  const [recentPageCount, setRecentPageCount] = useState(1);
  const [isUpcomingFetching, setIsUpcomingFetching] = useState(false);
  const [isRecentFetching, setIsRecentFetching] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [isSearchOn, setIsSearchOn] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const handleWebinarDetailsClick = (event_id, mode) => {
    navigate(`/WebinarDetails?event_id=${event_id}&mode=${mode}`);
  };
  console.log("upcomingWebinarEvents", upcomingWebinarEvents, recentWebinarEvents);
  useEffect(() => {
    console.log("Brother");
    const handleEffect = async () => {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      let upcomingData = await upcomingListGetter(1);
      const recentData = await recentListGetter(1);
      console.log("batch data", upcomingData);
      const registeredEvents = JSON.parse(localStorage.getItem("registeredEvents")) || [];

      // Function to append registered parameter
      upcomingData = upcomingData.map((event) => ({
        ...event,
        registered: registeredEvents.includes(parseInt(event.event_id)) ? 1 : 0,
      }));

      setUpcomingWebinarEvents(upcomingData);

      setRecentWebinarEvents(recentData);
      console.log("odonil", { registeredEvents, upcomingData });
      setLoading((prev) => ({ ...prev, upcoming_loader: false }));
    };
    handleEffect();
  }, []);

  const handleSearch = async (searchString) => {
    if (searchString === "") {
      setIsSearchOn(false);
    }
    setSearchString(searchString);
    console.log({ searchString });
  };

  const handleEnterSearch = (e) => {
    if (e.key === "Enter") {
      requestSearch(e.target.value);
    }
  };

  const requestSearch = async (searchString) => {
    try {
      let res;
      res = await service.get(`/user/event/event/search?searchQuery=${searchString}`);
      setIsSearchOn(true);
      setSearchResults(res.data);
      console.log("StudentResumeDataGet", res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollTop = window.scrollY;

    if (responseDataEmpty === false && windowHeight + scrollTop + 200 >= documentHeight && !isUpcomingFetching) {
      setIsUpcomingFetching(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isUpcomingFetching]);

  useEffect(() => {
    if (isUpcomingFetching) {
      upcomingListGetter(upcomingPageCount + 1);

      setIsUpcomingFetching(false);
    }
  }, [isUpcomingFetching, responseDataEmpty]);

  // past scrolling
  const handlePastScroll = () => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollTop = window.scrollY;

    if (recentResponseDataEmpty === false && windowHeight + scrollTop >= documentHeight && !isRecentFetching) {
      setIsRecentFetching(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handlePastScroll);

    return () => {
      window.removeEventListener("scroll", handlePastScroll);
    };
  }, [isRecentFetching]);

  useEffect(() => {
    if (isRecentFetching) {
      recentListGetter(recentPageCount + 1);
      setIsRecentFetching(false);
    }
  }, [isRecentFetching, recentResponseDataEmpty]);

  const handleEnterPressed = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const upcomingListGetter = async (page) => {
    setLoading((prev) => ({ ...prev, upcoming_loader: true }));
    try {
      const res = await service.get(`/user/event/event?past=0&page_no=${page}&page_size=2`);
      console.log("getting applicants data response:", res.data);
      let temp_change_short_list = res.data;

      if (temp_change_short_list.length) {
        setUpcomingWebinarEvents((prevApplicants) => [...prevApplicants, ...temp_change_short_list]);
        setUpcomingPageCount(page);
      } else {
        setResponseDataEmpty(true);
      }
      setLoading((prev) => ({ ...prev, upcoming_loader: false }));
      return temp_change_short_list;
    } catch (err) {
      console.log(err);
      setLoading((prev) => ({ ...prev, upcoming_loader: false }));
    }
  };

  const recentListGetter = async (page) => {
    setLoading((prev) => ({ ...prev, recent_loader: true }));
    try {
      const res = await service.get(`/user/event/event?past=1&page_no=${page}&page_size=2`);
      console.log("getting applicants data response:", res.data);
      let temp_change_short_list = res.data;

      if (temp_change_short_list.length) {
        setRecentWebinarEvents((prevApplicants) => [...prevApplicants, ...temp_change_short_list]);
        setRecentPageCount(page);
      } else {
        setRecentResponseDataEmpty(true);
      }
      setLoading((prev) => ({ ...prev, recent_loader: false }));
      return temp_change_short_list;
    } catch (err) {
      console.log(err);
      setLoading((prev) => ({ ...prev, recent_loader: false }));
    }
  };

  return (
    <>
      <Helmet>
        <title>Events | Invest in your Career | CareerCarve</title>
        <meta name="description" content="Explore our competitive pricing options for personalized placement training at CareerCarve. Invest in your professional goals to achieve career success." />
        <meta property="og:image" content="/public/logo_welcome.png" />
        <link rel="canonical" href="/ExpertConnect" />
        <script src="https://ebz-static.s3.ap-south-1.amazonaws.com/easecheckout/easebuzz-checkout.js"></script>
      </Helmet>
      <Box
        className="login-Container"
        style={{
          marginTop: !webinarTickerExists && Mobile ? "60px" : webinarTickerExists && Mobile ? "106px" : webinarTickerExists ? "120px" : "80px",
          width: Mobile ? "100%" : "max(100%,1200px)",
          ...(Mobile && {
            overflow: "hidden",
            width: "100%",
            maxWidth: "100%",
            overflowY: "auto",
          }),
        }}
      >
        <Box sx={{ width: Mobile ? "90%" : "80%", display: "flex", flexDirection: "row", gap: "2rem", margin: Mobile ? "0 auto 1rem auto" : "1.5rem auto 0 auto" }}>
          <TextField
            onChange={(e) => handleSearch(e.target.value)}
            onKeyDown={handleEnterSearch}
            width={"100%"}
            height={"40px"}
            sx={{ fontSize: "16px" }}
            placeholder="Search"
            InputProps={{
              style: { borderRadius: "50px", height: "35px" },
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        {isSearchOn ? (
          <Box className="upcoming">
            {searchResults?.map((webinar, index) => (
              <Box sx={{ width: Mobile ? "90%" : "80%", margin: "0 auto", display: "flex", flexDirection: "column", gap: "1rem", marginTop: "1rem" }}>
                <WebinarCard key={index} webinar={webinar} mode={webinar?.date > Date.now() ? "upcoming" : "past"} handleClick={() => handleWebinarDetailsClick(webinar?.event_id, webinar?.date > Date.now() ? "upcoming" : "past")} />
              </Box>
            ))}
          </Box>
        ) : (
          <Box sx={{ width: Mobile ? "90%" : "80%", margin: "0 auto", display: "flex", flexDirection: "column", gap: "1rem", marginTop:'1rem' }}>
            <Typography variant="h1" sx={{ fontSize: Mobile ? "20px" : "48px", textAlign: "left", color: Color.neutralDarkGrey }}>
              Upcoming Events
            </Typography>
            {upcomingWebinarEvents.length === 0 ? (
              <NoEventsMessage mode={"upcoming"} />
            ) : (
              <Box className="upcoming">
                {upcomingWebinarEvents?.map((webinar, index) => (
                  <WebinarCard key={index} webinar={webinar} mode={"upcoming"} handleClick={() => handleWebinarDetailsClick(webinar?.event_id, "upcoming")} />
                ))}
              </Box>
            )}

            {loading.upcoming_loader && upcomingWebinarEvents.length !== 0 && (
              <>
                <WebinarSkeletonLoader mode="upcoming" Mobile={Mobile} />
                <WebinarSkeletonLoader mode="upcoming" Mobile={Mobile} />
              </>
            )}
            <Typography sx={{ fontSize: Mobile ? "20px" : "48px", textAlign: "left", color: Color.neutralDarkGrey }}>Past Events</Typography>
            {recentWebinarEvents.length === 0 ? (
              <NoEventsMessage mode={"past"} />
            ) : (
              <Box className="upcoming">
                {recentWebinarEvents.map((webinar, index) => (
                  <WebinarCard key={index} mode="past" webinar={webinar} handleClick={() => handleWebinarDetailsClick(webinar?.event_id, "past")} />
                ))}
              </Box>
            )}

            {loading.recent_loader && recentWebinarEvents.length !== 0 && (
              <>
                <WebinarSkeletonLoader mode="past" Mobile={Mobile} />
                <WebinarSkeletonLoader mode="past" Mobile={Mobile} />
              </>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default WebinarEvents;
