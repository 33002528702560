import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
    Container,
    CssBaseline,
    Typography,
    Box,
    Card,
    CardContent,
    TextField,
    FormControlLabel,
    Checkbox,
    useMediaQuery,
    InputLabel
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { ValidateNameEmailPhone } from './Validations/FormFillValidations.js';
import RDService from '../../services/RDService';
import { Color } from '../../GlobalStyles';


const defaultTheme = createTheme();

const InputField = ({ label, name, value, onChange, error, helperText }) => (
    <Box>
        <Typography sx={{ marginBottom: ".5rem" }}>{label} <span style={{ color: "red" }}>*</span></Typography>
        <TextField
            fullWidth
            id={name}
            label={label}
            name={name}
            autoComplete={name}
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={helperText}
        />
    </Box>
);

const WebinarRegisterNow = (props) => {
    const { event_id, mode, registered, setRegistered, eventRegistered, setEventRegistered, handleWebinarEventsClick, isMobile,toastUpdates } = props;
    const service = new RDService();
    const Mobile = useMediaQuery('(max-width:800px)');
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        event_id: parseInt(event_id),
        name: '',
        email_id: '',
        ph_no: ''
    });
    console.log("what is event registered", eventRegistered);

    useEffect(() => {
        const registeredEvents = JSON.parse(localStorage.getItem('registeredEvents')) || [];
        setRegistered(registeredEvents.includes(parseInt(event_id)));
    }, [event_id]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { name, email_id, ph_no } = formData;
        const errors = ValidateNameEmailPhone({ fullname: name, email: email_id }, ph_no);
        setErrors(errors);
        if (Object.keys(errors).length > 0) {
            console.log("Validation errors:", errors);
            return;
        }
        try {
            const res = await service.post(`/user/event/register`, formData);
            console.log("response seeing", res);
            if (res.status === 200) {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                
                const registeredEvents = JSON.parse(localStorage.getItem('registeredEvents')) || [];
                localStorage.setItem('formData', JSON.stringify(formData));
                localStorage.setItem('registeredEvents', JSON.stringify([...registeredEvents, parseInt(event_id)]));
                toast.success(<>Registered successfully! <p>You will receive an email shortly</p></>);
                if (isMobile) {
                    setEventRegistered(true);
                } else {
                    setEventRegistered(true);
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main">
                <CssBaseline />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'left',
                    }}
                >
                    {(registered) || (mode === "past") ? (
                        <Card sx={{ boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.08)", borderRadius: "12px", width: Mobile ? "100%" : "80%" }}>
                            <CardContent>
                                <Box sx={{ padding: "1rem", display: "flex", flexDirection: "column", gap: "2rem" }}>
                                    <Typography sx={{ fontStyle: "italic", textAlign: "center", margin: "0 auto" }}>
                                        {mode === "upcoming" ? "Want to know more about upcoming events?" : "OOPS! This webinar has ended"}
                                    </Typography>
                                    <button onClick={handleWebinarEventsClick} className="pricingSingleACtivityButton1" style={{ backgroundColor: Color.registerColor, color: Color.white, textAlign: "center", margin: "0 auto" }}>
                                        Browse other webinars
                                    </button>
                                </Box>
                            </CardContent>
                        </Card>
                    ) : (
                        <Card sx={{ width: Mobile ? "100%" : "80%", boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.08)", ...(Mobile && { boxShadow: "none" }) }}>
                            <ToastContainer
                                position={Mobile ? "top-center" : "top-right"}
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                pauseOnHover
                                limit={1}
                                style={{
                                    position: 'absolute',
                                    ...(!Mobile && {
                                        top: '50%',
                                        right: '20%',
                                    }),
                                }}
                            />


                            {eventRegistered ? <Card sx={{ boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.08)", borderRadius: "12px" }}>
                                <CardContent>
                                    <Box sx={{ padding: "2rem", display: "flex", flexDirection: "column", gap: "2rem" }}>
                                        <Typography sx={{ fontStyle: "italic", textAlign: "center" }}>
                                            {mode === "upcoming" ? "Want to know more about upcoming events?" : "OOPS! This webinar has ended"}
                                        </Typography>
                                        <button onClick={handleWebinarEventsClick} className="pricingSingleACtivityButton1" style={{ backgroundColor: Color.registerColor, color: Color.white, textAlign: "center", margin: '0 auto' }}>
                                            Browse other webinars
                                        </button>
                                    </Box>
                                </CardContent>
                            </Card> :
                                <CardContent>
                                    <Typography component="h1" variant="h5" align="left" marginBottom={2}>
                                        Register now!!
                                    </Typography>
                                    {/* <Box component="form" onSubmit={(e) => { handleSubmit(e) }} noValidate sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                                        <InputField label="Name" placeholder="Enter you name" name="name" value={formData.name} onChange={handleInputChange} error={errors.firstname} helperText={errors.firstname} />
                                        <InputField label="Email" placeholder="Enter your mail ID" name="email_id" value={formData.email_id} onChange={handleInputChange} error={errors.email} helperText={errors.email} />
                                        <InputField label="Mobile Number" placeholder="Enter your mobile number" name="ph_no" value={formData.ph_no} onChange={handleInputChange} error={errors.phone} helperText={errors.phone} />
                                        <FormControlLabel
                                            control={<Checkbox checked value="remember" style={{ color: Color.green }} />}
                                            label="I agree and accept to all the terms and conditions"
                                        />
                                        <Box textAlign="center" mt={2}>
                                            <button onClick={handleSubmit} className="pricingSingleACtivityButton1" style={{ backgroundColor: Color.registerColor, color: Color.white }}>
                                                Register for Free
                                            </button>
                                        </Box>
                                    </Box> */}
                                    <Box noValidate sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                                        <Box >
                                            <InputLabel htmlFor="name">Name <span style={{ color: "red" }}>*</span></InputLabel>
                                            <TextField
                                                id="name"
                                                placeholder="Enter your name"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleInputChange}
                                                error={errors.firstname}
                                                helperText={errors.firstname}
                                                sx={{ width: '100%' }}
                                            />
                                        </Box>
                                        <Box>
                                            <InputLabel htmlFor="email">Email<span style={{ color: "red" }}>*</span></InputLabel>
                                            <TextField
                                                id="email"
                                                placeholder="Enter your mail ID"
                                                name="email_id"
                                                value={formData.email_id}
                                                onChange={handleInputChange}
                                                error={errors.email}
                                                helperText={errors.email}
                                                sx={{ width: '100%' }}
                                            />
                                        </Box>
                                        <Box>
                                            <InputLabel htmlFor="phone">Mobile Number<span style={{ color: "red" }}>*</span></InputLabel>
                                            <TextField
                                                id="phone"
                                                placeholder="Enter your mobile number"
                                                name="ph_no"
                                                value={formData.ph_no}
                                                onChange={handleInputChange}
                                                error={errors.phone}
                                                helperText={errors.phone}
                                                sx={{ width: '100%' }}
                                            />
                                        </Box>
                                        <FormControlLabel
                                            control={<Checkbox checked value="remember" style={{ color: Color.green }} />}
                                            label="I agree and accept to all the terms and conditions"
                                        />
                                        <Box textAlign="center" mt={2}>
                                            <button onClick={handleSubmit} className="pricingSingleACtivityButton1" style={{ backgroundColor: Color.registerColor, color: Color.white }}>
                                                Register for Free
                                            </button>
                                        </Box>
                                    </Box>

                                </CardContent>}


                        </Card>
                    )}
                </Box>
            </Container>
        </ThemeProvider>
    );
}

export default WebinarRegisterNow
