import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { Typography, Box } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { Color } from '../../GlobalStyles';
import { ExpertPanelSkeleton } from './SkeletonLoaders/WebinarDetailsSkeletonLoader';
import { get_specialCharReplace } from '../../services/specialCharReplace';
export default function ExpertPanel(props) {
    const Mobile = useMediaQuery('(max-width:600px)');
    const { webinarDetails, loading, registered, eventRegistered, setRegistered, setEventRegistered } = props
    console.log("webinarDetails", webinarDetails, JSON.parse(webinarDetails?.detail));

    return (
        <>
            {loading.details_loader ? <ExpertPanelSkeleton Mobile={Mobile} /> : <Grid container spacing={2} style={{
                ...(!Mobile && { marginBottom: "5rem" }), // Apply margin bottom if not mobile
                ...(Mobile && (registered || eventRegistered) && { marginBottom: "5rem" }) // Apply margin bottom if mobile and not registered or eventRegistered
            }}
            >
                {JSON.parse(webinarDetails?.detail)?.members.map((expert, index) => (
                    <Grid item key={index} xs={12} sm={6} md={4}>
                        <Card
                            sx={{
                                width: Mobile ? '100%' : '72%',
                                height: '100%',
                                display: 'flex',
                                gap: ".8rem",
                                borderRadius: "12px",
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.08)",
                                padding: '16px', // Added padding
                            }}
                        >
                            {/* <CardContent sx={{ textAlign: 'center', display: "flex", flexDirection: Mobile ? "row" : "column", gap: ".8rem" }}>
                            <img src={expert.image} alt={expert.name} style={{ width: '8rem', height: '8rem', borderRadius: "100%", margin: 'auto' }} />
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                <Typography variant="h5" fontWeight={400} whiteSpace={"nowrap"}>{expert.name}</Typography>
                                <Typography variant="body1" style={{ whiteSpace: 'pre-line', color: Color.neutralMidGrey, fontSize: Mobile ? "12px" : "14px", textAlign: Mobile ? "left" : "center" }}>
                                    {expert.description.split('\n').map((line, i) => (
                                        <React.Fragment key={i}>
                                            {line}
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </Typography>
                            </Box>
                        </CardContent> */}
                            <CardContent sx={{
                                ...(Mobile && {
                                    padding: 0, paddingBottom: 0
                                }), textAlign: 'center', display: "flex", flexDirection: Mobile ? "row" : "column", gap: ".8rem"
                            }} style={{ ...(Mobile && { paddingBottom: '0' }) }}>
                                {(Mobile && index % 2 !== 0) && (
                                    <>
                                        <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                            <Typography variant="h5" fontWeight={400} whiteSpace={"nowrap"} sx={{ fontSize: Mobile ? "14px" : "16px", textAlign: Mobile ? "left" : "center", fontWeight: "bold" }}>{expert.name}</Typography>
                                            <Typography variant="body1" style={{ whiteSpace: 'pre-line', color:Mobile ? Color.neutralDarkGrey : Color.neutralMidGrey, fontSize: Mobile ? "11px" : "14px", textAlign: Mobile ? "left" : "center" }}>
                                                {expert.description.split('||n').map((line, i, arr) => (
                                                    <React.Fragment key={i}>
                                                        {i === arr.length - 1 ? <b>{line}</b> : line}
                                                        <br />
                                                    </React.Fragment>
                                                ))}
                                            </Typography>
                                        </Box>
                                        <img src={expert.image} alt={expert.name} style={{ width: Mobile ? "6rem" : '120px', height: Mobile ? "6rem" : '120px', borderRadius: "100%", margin: 'auto', borderColor: Color.primary1, borderStyle: 'solid', borderWidth: '2px',objectFit: "cover" }} />
                                    </>
                                )}
                                {(!Mobile || (Mobile && index % 2 === 0)) && (
                                    <>
                                        <img src={expert.image} alt={expert.name} style={{
                                            width: Mobile ? "6rem" : '120px', height: Mobile ? "6rem" : '120px', borderRadius: "100%", margin: 'auto', borderColor: Color.primary1, borderStyle: 'solid', borderWidth: '2px',objectFit: "cover"
                                        }} />
                                        <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                                            <Typography variant="h5" fontWeight={400} whiteSpace={"nowrap"} sx={{ fontSize: Mobile ? "14px" : "16px", textAlign: Mobile ? "left" : "center", fontWeight: "bold" }}>{expert.name}</Typography>
                                            <Typography variant="body1" style={{ whiteSpace: 'pre-line', color:Mobile ? Color.neutralDarkGrey : Color.neutralMidGrey, fontSize: Mobile ? "12px" : "14px", textAlign: Mobile ? "left" : "center" }}>
                                                {expert.description.split('||n').map((line, i, arr) => (
                                                    <React.Fragment key={i}>
                                                        {i === arr.length - 1 ? <b>{line}</b> : line}
                                                        <br />
                                                    </React.Fragment>
                                                ))}
                                            </Typography>
                                        </Box>
                                    </>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>}
        </>
    );
}
