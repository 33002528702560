import * as React from "react";
import { Instagram, LinkedIn, MailOutline } from "@mui/icons-material";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import { Box, Button, CircularProgress, Container, IconButton, TextField, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import RDService from "../../../services/RDService";
import "./ContactUs.css";
export default function ContactUs() {
  const Mobile = useMediaQuery("(max-width:800px)");
  const services = new RDService();
  const APKUrl = "https://play.google.com/store/apps/details?id=com.careercarve";
  const iOSUrl = "https://apps.apple.com/in/app/careercarve/id6447052699";

  const [contactForm, setContactForm] = React.useState({
    name: "",
    ph_no: "",
    email: "",
    comments: "",
  });

  const [formError, setFormError] = React.useState({
    name: "",
    ph_no: "",
    email: "",
    comments: "",
  });

  const [loader, setLoader] = React.useState(false);

  const navigate = useNavigate();
  const validateContactForm = () => {
    let validation = true;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (contactForm.name === "") {
      setFormError((prev) => {
        return {
          ...prev,
          name: "Name is Required",
        };
      });
      validation = false;
    }
    if (contactForm.email === "") {
      setFormError((prev) => {
        return {
          ...prev,
          email: "Email is required",
        };
      });
      validation = false;
    }

    if (!emailRegex.test(contactForm.email)) {
      setFormError((prev) => {
        return {
          ...prev,
          email: "Enter a valid email",
        };
      });
      validation = false;
    }
    if (contactForm.ph_no === "" || contactForm.ph_no.length !== 10) {
      setFormError((prev) => {
        return {
          ...prev,
          ph_no: "Enter a valid Contact Number",
        };
      });
      validation = false;
    }
    if (contactForm.comments === "") {
      setFormError((prev) => {
        return {
          ...prev,
          comments: "Enter a comment",
        };
      });
      validation = false;
    }

    return validation;
  };

  const [buttoninput, setButtonInput] = React.useState("Submit");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormError({ name: "", ph_no: "", email: "", comments: "" });
    if (name === "name") {
      setContactForm((prev) => {
        return {
          ...prev,
          name: value,
        };
      });
    } else if (name === "ph_no") {
      setContactForm((prev) => {
        return {
          ...prev,
          ph_no: value,
        };
      });
    } else if (name === "email") {
      setContactForm((prev) => {
        return {
          ...prev,
          email: value,
        };
      });
    } else if (name === "comments") {
      setContactForm((prev) => {
        return {
          ...prev,
          comments: value,
        };
      });
    }
  };

  const handleSubmit = async () => {
    if (validateContactForm()) {
      setLoader(true);
      const body = {
        name: contactForm.name,
        email: contactForm.email,
        ph_no: contactForm.ph_no,
        comments: contactForm.comments,
      };

      const postMessage = await services.post("/contact", body);
      console.log("", postMessage);
      if (postMessage.data.success) {
        setContactForm({
          name: "",
          ph_no: "",
          email: "",
          comments: "",
        });
      }
      setButtonInput(<CheckCircleOutlineRoundedIcon style={{ fontSize: "28px" }} />);
      setTimeout(() => {
        setButtonInput("Submit");
      }, 2000);
      setLoader(false);
    } else {
      console.log("error ", formError);
      setLoader(false);
    }
  };
  return (
    <div style={{}}>
      <Container
        sx={{
          width: Mobile ? "100%" : "100%",
          display: "flex",
          justifyContent: "space-between",
          // alignItems: "center",
          flexDirection: Mobile ? "column" : "row", // center horizontally
        }}
      >
        <Container
          sx={{
            width: Mobile ? "100%" : "50%",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h4"
            fontSize="24px"
            align="center"
            sx={{
              position: "relative",
              color: "#fff",
              "&::after": {
                content: "''",
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                bottom: "-10%",
                width: "64px",
                borderBottom: "2px solid #1C8EA8",
              },
            }}
          >
            Download Mobile App
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", marginTop: "40px" }}>
            {!Mobile && <img src="../../QR_code_sign_up.jpg" style={{ height: "100%", width: "30%" }} alt="careercarve" />}
            <Container
              sx={{
                display: "flex",
                flexDirection: "column",

                alignItems: "center", // add this property
                textAlign: "center",
                gap: Mobile ? "10px" : "33px",
                paddingLeft: Mobile ? "0px" : "16px",
                paddingRight: Mobile ? "0px" : "16px",
              }}
            >
              {/* <GooglePlayButton
                url={APKUrl}
                theme="light"
                className="download-buttons"
                // width="100%"
                style={{ border: "red" }}
                // height="66px"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
              /> */}
              <a href="https://play.google.com/store/apps/details?id=com.careercarve" target="_blank" rel="noopener" style={{ textDecoration: "none", width: "100%" }}>
                <Box
                  sx={{
                    border: "1px solid #fff",

                    height: "66px",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "10px",
                    justifyContent: "center",
                    gap: "10px",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                >
                  <img src="../../../google-play 1.svg" alt="CareerCarve" />
                  <Box>
                    <Typography
                      sx={{
                        color: "#E6E6E6",
                        textAlign: "left",
                        fontSize: "14px",
                      }}
                    >
                      Get it on
                    </Typography>
                    <Typography
                      sx={{
                        color: "#fff",
                        textAlign: "left",
                        fontSize: "22px",
                      }}
                    >
                      Google Play
                    </Typography>
                  </Box>
                </Box>
              </a>
              <a href="https://apps.apple.com/in/app/careercarve/id6447052699" target="_blank" rel="noopener" style={{ textDecoration: "none", width: "100%" }}>
                <Box
                  sx={{
                    border: "1px solid #fff",
                    width: "100%",
                    height: "66px",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "10px",
                    justifyContent: "center",
                    gap: "10px",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  href={iOSUrl}
                >
                  <img src="../../../app-store 1.svg" alt="CareerCarve" />
                  <Box>
                    <Typography
                      sx={{
                        color: "#E6E6E6",
                        textAlign: "left",
                        fontSize: "14px",
                      }}
                    >
                      Download from
                    </Typography>
                    <Typography
                      sx={{
                        color: "#fff",
                        textAlign: "left",
                        fontSize: "22px",
                      }}
                    >
                      App Store
                    </Typography>
                  </Box>
                </Box>
              </a>
              {/* <AppStoreButton
                url={iOSUrl}
                theme="light"
                className="download-buttons"
                width="100%"
                height="66px"
              /> */}
            </Container>
          </Box>

          {/* <Typography variant="h5" align="center" 
          
          sx={{
            position: "relative",
            "&::after": {
              content: "''",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              bottom: "-10%",
              width: "64px",
              
              borderBottom: "2px solid #1C8EA8",
            },
            marginTop: "64px",
          }}>
            Connect with us
          </Typography>
          <Container
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "22px",
              gap:Mobile ? "10px" : "22px",
              paddingLeft:Mobile ? 0 : "16px",
              paddingRight:Mobile ? 0 : "16px"
            }}
          >
            <Button
              variant="outlined"
              color="inherit"
              size="large"
              fullWidth
              sx={{
                borderRadius: "37px",
                gap: "12px",
                borderRadius: "20px",
                border: "1px solid #E6E6E6",
                boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.125)",
              }}
            >
              <InstagramIcon /> Follow
            </Button>
            <Button
              variant="outlined"
              color="inherit"
              size="large"
              fullWidth
              sx={{
                borderRadius: "37px",
                gap: "12px",
                borderRadius: "20px",
                // border: "none",
                border: "1px solid #E6E6E6",
                boxShadow: "0px 0px 15px rgba(0, 0, 0,0.125)",
              }}
            >
              <LinkedInIcon />
              Follow
            </Button>
          </Container> */}

          <Box sx={{ marginTop: "2rem" }}>
            <Typography
              variant="h4"
              fontSize="24px"
              align="center"
              sx={{
                position: "relative",
                marginBottom: "1rem",
                color: "#fff",
                "&::after": {
                  content: "''",
                  position: "absolute",
                  left: "50%",
                  transform: "translateX(-50%)",
                  bottom: "-10%",
                  width: "10%",
                  borderBottom: "2px solid #1C8EA8",
                },
              }}
            >
              Reach out to us
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                justifyContent: "center",
              }}
            >
              <IconButton
                sx={{
                  boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                  padding: "10px",
                  border: "1px solid #fff",
                }}
                href="https://instagram.com/_careercarve_?igshid=MzRlODBiNWFlZA=="
                target="_blank"
              >
                <Instagram sx={{ fontSize: "30px", color: "#fff" }} />
              </IconButton>
              <IconButton
                sx={{
                  boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                  padding: "10px",
                  border: "1px solid #fff",
                }}
                href="https://in.linkedin.com/company/careercarve"
                target="_blank"
              >
                <LinkedIn sx={{ fontSize: "30px", color: "#fff" }} />
              </IconButton>{" "}
              <IconButton
                sx={{
                  boxShadow: "0px 0px 15px 0px rgba(0, 0, 0, 0.08)",
                  padding: "10px",
                  border: "1px solid #fff",
                }}
                href="https://mail.google.com/mail/?view=cm&to=admin%40careercarve.com"
                target="_blank"
              >
                <MailOutline
                  sx={{
                    fontSize: "30px",
                    color: "#fff",
                  }}
                />
              </IconButton>
            </Box>
          </Box>
        </Container>

        <Container
          sx={{
            marginTop: Mobile ? "49px" : "0px",
            width: Mobile ? "100%" : "50%",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h4"
            fontSize="24px"
            align="center"
            sx={{
              position: "relative",
              color: "#fff",
              "&::after": {
                content: "''",
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                bottom: "-10%",
                width: "10%",
                borderBottom: "2px solid #1C8EA8",
              },
            }}
          >
            Contact
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "16px",
              width: "100%",
              marginTop: "36px",
            }}
          >
            <TextField
              id="outlined-basic"
              name="name"
              autoComplete="off"
              InputProps={{
                style: {
                  color: "#fff",
                },
              }}
              InputLabelProps={{
                style: {
                  color: "#fff",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#fff",
                  },
                  "&:hover fieldset": {
                    borderColor: "#fff",
                  },

                  "&.Mui-focused fieldset": {
                    borderColor: "#fff",
                  },
                },
              }}
              error={formError.name === "" ? false : true}
              helperText={formError.name}
              label="Full Name"
              onChange={(e) => handleChange(e)}
              variant="outlined"
              value={contactForm.name}
              placeholder="Enter your name"
              fullWidth
            />
            <TextField
              id="outlined-basic"
              label="Mobile Number"
              InputProps={{
                style: {
                  color: "#fff",
                },
              }}
              InputLabelProps={{
                style: {
                  color: "#fff",
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "#fff",
                  },
                  "&:hover fieldset": {
                    borderColor: "#fff",
                  },

                  "&.Mui-focused fieldset": {
                    borderColor: "#fff",
                  },
                },
              }}
              error={formError.ph_no === "" ? false : true}
              helperText={formError.ph_no}
              name="ph_no"
              autoComplete="off"
              onChange={(e) => handleChange(e)}
              value={contactForm.ph_no}
              variant="outlined"
              placeholder="Enter your Phone Number"
              fullWidth
            />
          </div>
          <TextField
            id="outlined-basic"
            InputProps={{
              style: {
                color: "#fff",
                backgroundColor: "transparent",
              },
            }}
            InputLabelProps={{
              style: {
                color: "#fff",
                backgroundColor: "transparent",
              },
            }}
            sx={{
              marginTop: "16px",
              backgroundColor: "transparent",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#fff",
                },
                "&:hover fieldset": {
                  borderColor: "#fff",
                },

                "&.Mui-focused fieldset": {
                  borderColor: "#fff",
                },
              },
            }}
            label="Email"
            error={formError.email === "" ? false : true}
            helperText={formError.email}
            name="email"
            autoComplete="off"
            onChange={(e) => handleChange(e)}
            variant="outlined"
            value={contactForm.email}
            placeholder="Enter your email id"
            fullWidth
          />
          <TextField
            id="outlined-basic"
            name="comments"
            label="Message"
            onChange={(e) => handleChange(e)}
            value={contactForm.comments}
            error={formError.comments === "" ? false : true}
            helperText={formError.comments}
            variant="outlined"
            placeholder="Enter your Comments"
            fullWidth
            multiline
            rows={4}
            InputProps={{
              style: {
                color: "#fff",
              },
            }}
            InputLabelProps={{
              style: {
                color: "#fff",
              },
            }}
            sx={{
              marginTop: "16px",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#fff",
                },
                "&:hover fieldset": {
                  borderColor: "#fff",
                },

                "&.Mui-focused fieldset": {
                  borderColor: "#fff",
                },
              },
            }}
          />

          <Button
            variant="contained"
            fullWidth
            sx={{
              marginTop: "16px",
              height: "44px",
              background: "linear-gradient(99.23deg, #2AA8C4 -9.01%, #38C1DF 103.32%)",
              borderRadius: "11px",
              // marginBottom: "66px",
            }}
            onClick={() => handleSubmit()}
          >
            {loader ? <CircularProgress sx={{ color: "#fff" }} size={24} /> : buttoninput}
          </Button>
        </Container>
      </Container>
    </div>
  );
}
